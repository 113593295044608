export interface LogItem {
    time: string
    level: string
    message: string
    logMsg: object
}

export interface Status {
    host: string
    uptime: number
    counter: number
    time: string,
    log: LogItem[]
}

export async function fetchStatus(useLogin: boolean, username: string, password: string) {
    let result
    try {
        const response = await fetch(`/API/status`, useLogin ? {
            headers: {
                Authorization: `Basic ${btoa(`${username.toLowerCase()}:${password}`)}`,
            },
        } : undefined);
        const data = await response.json() as Status
        data.log.reverse()
        result = data
    } catch {

    }
    return result;
}
