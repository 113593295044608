import React, { useEffect, useRef, useState } from "react";
import { fetchStatus, Status } from "./api";
import { JsonView, darkStyles, defaultStyles } from 'react-json-view-lite'
import 'react-json-view-lite/dist/index.css'
import "./App.css";
import Cookies from 'js-cookie'

const styling = {
  table: {
    //border: "1px solid black",
  },
  th_td: {
    //border: "1px solid black",
    padding: "5px",
    textAlign: "left" as "left"
  },
  root: {
    margin: "10px",
    fontSize: "12px"
  }
}

function secondsToDhms(seconds: number) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor(seconds % (3600 * 24) / 3600);
  var m = Math.floor(seconds % 3600 / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return (dDisplay + hDisplay + mDisplay + sDisplay).replace(/,\s*$/, "");
}

const itemsPerPageOptions = [10, 25, 100]; // Number of items per page options
const maxVisiblePages = 5; // Maximum number of visible page links (including dots)

function App(props: { useLogin?: boolean }) {
  const useLogin = props.useLogin
  const [status, setStatus] = useState<Status | null>(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined)
  const [invalidLogin, setInvalidLogin] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0])
  const [totalPages, setTotalPages] = useState(1)
  const refTotalPages = useRef(1)

  useEffect(() => {
    const loggedInUser = Cookies.get('loggedInUser');
    if (loggedInUser && loggedInUser.indexOf(':') >= 0) {
      setUsername(loggedInUser.split(':')[0])
      setPassword(loggedInUser.split(':')[1])
      setInvalidLogin(false)
      setIsLoggedIn(true)
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        const status = await fetchStatus(!!useLogin, username, password)
        if (status) {
          setStatus(status);
          const tot = Math.ceil(status.log.length / itemsPerPage)
          setTotalPages(tot)
          refTotalPages.current = tot
        }
      }
    })()
  }, useLogin ? [isLoggedIn, username, password] : []);

  async function fetchAndSetItems() {
    const status = await fetchStatus(!!useLogin, username, password);
    if (status) {
      setInvalidLogin(false)
      setIsLoggedIn(true)
      setStatus(status);
      const tot = Math.ceil(status.log.length / itemsPerPage)
      setTotalPages(tot)
      refTotalPages.current = tot
  } else
      setInvalidLogin(true)
  }
  async function handleLogin(e: any) {
    e.preventDefault()
    if (username === 'pikachu' && password === 'grankullen17') {
      setInvalidLogin(false)
      setIsLoggedIn(true)
      Cookies.set('loggedInUser', username + ':' + password, { expires: 30 });
      const status = await fetchStatus(true, username, password);
    } else
        setInvalidLogin(true)
  }
  async function logout() {
    setIsLoggedIn(false)
    setUsername('')
    setPassword('')
  }
  async function toNodeRED() {
    window.location.assign('/node-red')
  }
  async function toDashboard() {
    window.location.assign(`/ui`)
  }
  async function toCAM02() {
    window.location.assign(`/cameras/cam02`)
  }
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleLogin(e);
    }
  }

  function handleFirstPage() {
    setCurrentPage(1)
  }

  function handleNextPage() {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  function handlePrevPage() {
    setCurrentPage((prevPage) => prevPage - 1)
  }

  function handleLastPage() {
    setCurrentPage(refTotalPages.current)
  }

  function handleItemsPerPageChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset the current page to the first page when items per page changes
  }

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = status ? status.log.slice(indexOfFirstItem, indexOfLastItem) : []

  // Calculate the range of page links to display
  let pageRange: number[] = [];
  if (totalPages <= maxVisiblePages) {
    pageRange = Array.from({ length: totalPages }, (_, i) => i + 1);
  } else {
    const middlePage = Math.ceil(maxVisiblePages / 2);
    let lowerBound = currentPage - middlePage + 1;
    let upperBound = currentPage + middlePage - 1;

    if (lowerBound < 1) {
      lowerBound = 1;
      upperBound = maxVisiblePages;
    } else if (upperBound > totalPages) {
      upperBound = totalPages;
      lowerBound = totalPages - maxVisiblePages + 1;
    }  
    pageRange = Array.from({ length: maxVisiblePages }, (_, i) => lowerBound + i)
  }

  return (
    <div className="login-form" style={styling.root}>
      {(isLoggedIn || !useLogin) ? <>
        <h3>Pool Control</h3>
        <button onClick={fetchAndSetItems}>Refresh</button>
        <button style={{ marginLeft: "5px" }} onClick={toDashboard}>Dashboard</button>
        <button style={{ marginLeft: "5px" }} onClick={toNodeRED}>NodeRED</button>
        <button style={{ marginLeft: "5px" }} onClick={toCAM02}>Pool camera</button>
        {useLogin ?
          <button style={{ marginLeft: "5px" }} onClick={logout}>Logout</button> : <></>
        }
        {status ? <>
          <h4>Status</h4>
          <table style={styling.table}>
            <thead>
              <tr>
                <th style={styling.th_td}>Property</th>
                <th style={styling.th_td}>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={styling.th_td}>Host</td>
                <td style={styling.th_td}>{status.host}</td>
              </tr>
              <tr>
                <td style={styling.th_td}>Uptime</td>
                <td style={styling.th_td}>{secondsToDhms(status.uptime)}</td>
              </tr>
              <tr>
                <td style={styling.th_td}>System time</td>
                <td style={styling.th_td}>{(new Date(status.time)).toLocaleString()}</td>
              </tr>
              <tr>
                <td style={styling.th_td}>Life counter</td>
                <td style={styling.th_td}>{status.counter}</td>
              </tr>
            </tbody>
          </table>
          <h4>Log</h4>
          {status.log.length ?
            <>
              <table style={styling.table}>
                <thead>
                  <tr>
                    <th style={styling.th_td}>Time</th>
                    <th style={styling.th_td}>Level</th>
                    <th style={styling.th_td}>Message</th>
                    <th style={styling.th_td}>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((item, i) => (
                    <tr key={i}>
                      <td style={styling.th_td}>{(new Date(item.time)).toLocaleString()}</td>
                      <td style={styling.th_td}>{item.level.toUpperCase()}</td>
                      <td style={styling.th_td}>{item.message}</td>
                      <td style={styling.th_td}>
                        <React.Fragment>
                          <JsonView data={item.logMsg as object} shouldInitiallyExpand={(level) => false} style={defaultStyles} />
                        </React.Fragment>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p />
              <div className="pagination">
                <span className="current-page">Page {currentPage} ({totalPages})</span>
                <button onClick={handleFirstPage} disabled={currentPage === 1}>
                  &lt;&lt;
                </button>
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                  &lt;
                </button>
                {pageRange[0] > 1 && <span>...</span>}
                {pageRange.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => setCurrentPage(pageNumber)}
                    className={pageNumber === currentPage ? "active" : ""}
                  >
                    {pageNumber}
                  </button>
                ))}
                {pageRange[pageRange.length - 1] < totalPages && <span>...</span>}
                <button onClick={handleNextPage} disabled={indexOfLastItem >= status.log.length}>
                  &gt;
                </button>
                <button onClick={handleLastPage} disabled={currentPage === 1}>
                  &gt;&gt;
                </button>
                <span>Items per page:</span>
                <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                  {itemsPerPageOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </> : <>
              No log data
            </>}
        </> : <>
          Waiting for status response...
        </>}
      </> : null}
      {(!isLoggedIn && useLogin) ? <>
        <div>
          <h3>Pool Control</h3>
          <h4>Login</h4>
          <form onSubmit={handleLogin}>
            <label htmlFor="username">
              <span>Username:</span>
              <input id="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} onKeyDown={handleKeyDown} />
            </label>
            <label htmlFor="password">
              <span>Password:</span>
              <input id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown} />
            </label>
            <button type="submit" >Login</button>
            {invalidLogin ? <p style={{ color: "red" }}>Invalid login</p> : null}
          </form>
        </div>
      </> : null}
    </div>
  );
}

export default App;
