import React from 'react';
import './AppHome.css';

const App: React.FC = () => {
  return (
    <div className="app-container">
      <h1 className="title">Welcome to Pool Control!</h1>
      <div className="image-container">
        <img className="house-image" src="house.png" alt="House with Pool" />
      </div>
      <a className="pool-link" href="https://pool.pool-control.se" target="_self" rel="noopener noreferrer">
        <button className="pool-button">POOL</button>
      </a>
    </div>
  );
}

export default App;
